
import * as Yup from "yup";
import { defineComponent, ref, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import {
  Crawler,
  ICrawler,
  getCrawler,
  retryCrawler,
  forceStopCrawler,
  deleteCrawler,
} from "./logCrawler";
import { getProjectList } from "@/core/helpers/generalHelper";
import moment from "moment";
import ApiService from "@/core/services/ApiService";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
  name: "LogUserPage",
  components: {
    Datatable,
    ErrorMessage,
    Field,
    Form,
  },
  setup() {
    const store = useStore();
    const submitCrawlerButton = ref<HTMLElement | null>(null);
    const FormValidator = Yup.object().shape({
      source: Yup.string().required().label("Source"),
      project: Yup.string().required().label("Project"),
      tanggalAkhir: Yup.string().required().label("Tanggal Akhir"),
      tanggalawal: Yup.string().required().label("Tanggal Awal"),
    });
    const tableHeaderCrawler = ref([
      {
        name: "Crawler Name",
        key: "file_name",
        sortable: true,
      },
      {
        name: "Project",
        key: "project_name",
        sortable: true,
      },
      {
        name: "Data Type",
        key: "source",
        sortable: true,
      },
      {
        name: "Status",
        key: "status",
        sortable: true,
      },
      {
        name: "Created At",
        key: "created_at",
        sortable: true,
      },
      {
        name: "Start Date",
        key: "start_date",
        sortable: true,
      },
      {
        name: "End Date",
        key: "end_date",
        sortable: true,
      },
      {
        name: "Action",
        key: "aksi",
      },
    ]);
    const tableDataCrawler = ref<Array<ICrawler>>(Crawler);
    const tableKeyCrawler = ref(0);
    const FormDisplay = ref(false);
    const totalDataCrawler = ref(0);
    const is_loading = ref(true);
    const titleTabs = ref("Log Crawler");
    const itemsPerPage = ref(10);
    const currentPage = ref(1);
    const TypeValue = ref("");
    const modelSelect = ref("");
    const dataProject = ref([]);
    const selectedStatus = ref("all");
    const selectedDataType = ref("all");
    const dataSource = ref([
      {
        value: "media_tv",
        label: "Media TV",
      },
      {
        value: "media_cetak",
        label: "Media Cetak",
      },
      {
        value: "news",
        label: "Media Online",
      },
      {
        value: "medsos",
        label: "Media Sosial",
      },
      {
        value: "radio",
        label: "Radio",
      },
    ]);
    const statusOptions = ref([
      {
        value: "all",
        label: "Semua Status",
      },
      {
        value: "Success",
        label: "Berhasil",
      },
      {
        value: "Running",
        label: "Berjalan",
      },
      {
        value: "Pending",
        label: "Pending",
      },
      {
        value: "Failed",
        label: "Gagal",
      },
      {
        value: "Stopped",
        label: "Berhenti",
      },
    ]);
    const dataTypeOptions = ref([
      {
        value: "all",
        label: "Semua Media",
      },
      {
        value: "media_tv",
        label: "Media TV",
      },
      {
        value: "media_cetak",
        label: "Media Cetak",
      },
      {
        value: "news",
        label: "Media Online",
      },
      {
        value: "medsos",
        label: "Media Sosial",
      },
      {
        value: "radio",
        label: "Radio",
      },
    ]);
    const tanggalAwal = ref("");
    const tanggalAkhir = ref("");

    const writeContent = (text: string) => {
      var new_text = text;
      new_text = new_text.replace(/\n/g, "<br />");
      return new_text;
    };
    const startCrawlback = async () => {
      if (submitCrawlerButton.value) {
        submitCrawlerButton.value.setAttribute("data-kt-indicator", "on");
        let data = {
          id_project: modelSelect.value,
          id_user: store.getters.currentUser.data._id,
          source: TypeValue.value,
          start_date: moment(tanggalAwal.value).format("YYYY-MM-DD 00:00:00"),
          end_date: moment(tanggalAkhir.value).format("YYYY-MM-DD 23:59:59"),
        };
        var range_value = moment(data.end_date).diff(data.start_date, "days");
        if (range_value > 30) {
          submitCrawlerButton.value?.removeAttribute("data-kt-indicator");
          Swal.fire({
            position: "top-end",
            icon: "warning",
            toast: true,
            title: "Perhatian! Maksimal crawlback adalah 30 hari",
            showConfirmButton: false,
            timer: 3000,
          });
        } else {
          const params = {
            id_project: data.id_project,
            id_user: store.getters.currentUser.data._id,
            source: data.source,
            start_date: data.start_date,
            end_date: data.end_date,
          };
          const query = Object.keys(params)
            .map((key) => `${key}=${params[key]}`)
            .join("&");
          const url = `${process.env.VUE_APP_API_CRAWLER_URL}/siputra/crawlback-run?${query}`;
          ApiService.post(url, {})
            .then((response) => {
              if (response.status == 200) {
                fetchDataCrawler();
                Swal.fire({
                  position: "top-end",
                  icon: "success",
                  toast: true,
                  title: "Berhasil! Crawler berhasil dijalankan",
                  showConfirmButton: false,
                  timer: 3000,
                });
                TypeValue.value = "";
                modelSelect.value = "";
                tanggalAwal.value = "";
                tanggalAkhir.value = "";
                fetchDataCrawler();
              } else {
                Swal.fire({
                  position: "top-end",
                  icon: "error",
                  toast: true,
                  title: "Gagal! Terjadi kesalahan saat menjalankan crawler",
                  showConfirmButton: false,
                  timer: 3000,
                });
              }
            })
            .catch(() => {
              Swal.fire({
                position: "top-end",
                icon: "error",
                toast: true,
                title: "Gagal! Terjadi kesalahan saat menjalankan crawler",
                showConfirmButton: false,
                timer: 3000,
              });
            })
            .finally(() => {
              submitCrawlerButton.value?.removeAttribute("data-kt-indicator");
            });
        }
      }
    };
    const getListProject = async () => {
      const res = await getProjectList();
      dataProject.value = res;
    };
    const fetchDataCrawler = async () => {
      is_loading.value = true;
      const data = {
        ...(selectedDataType.value != "all"
          ? { source: selectedDataType.value }
          : {}),
        ...(selectedStatus.value != "all"
          ? { status: selectedStatus.value }
          : {}),
        page: currentPage.value,
        items: itemsPerPage.value,
      };
      const res = await getCrawler(data);
      tableDataCrawler.value = res.data.crawlback;
      totalDataCrawler.value =res.data.page_info.total || 0;
      is_loading.value = false;
      tableKeyCrawler.value += 1;
    };
    const retrySelectedCrawler = (id: string) => {
      Swal.fire({
        title: "Apakah anda yakin ingin menjalankan ulang crawler tersebut?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.value) {
          const res = await retryCrawler(id);
          if (res.status == 200) {
            Swal.fire({
              position: "top-end",
              icon: "success",
              toast: true,
              title: "Berhasil! Crawler berhasil dijalankan ulang",
              showConfirmButton: false,
              timer: 3000,
            });
            fetchDataCrawler();
          } else {
            Swal.fire({
              position: "top-end",
              icon: "error",
              toast: true,
              title: "Gagal! Terjadi kesalahan saat menjalankan ulang crawler",
              showConfirmButton: false,
              timer: 3000,
            });
          }
        }
      });
    };
    const forceStopSelectedCrawler = (id: string) => {
      Swal.fire({
        title: "Apakah anda yakin ingin memaksa berhenti crawler tersebut?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.value) {
          const res = await forceStopCrawler(id);
          if (res.status == 200) {
            Swal.fire({
              position: "top-end",
              icon: "success",
              toast: true,
              title: "Berhasil! Crawler telah dihentikan",
              showConfirmButton: false,
              timer: 3000,
            });
            fetchDataCrawler();
          } else {
            Swal.fire({
              position: "top-end",
              icon: "error",
              toast: true,
              title: "Gagal! Terjadi kesalahan saat menghentikan crawler",
              showConfirmButton: false,
              timer: 3000,
            });
          }
        }
      });
    };
    const deleteSelectedCrawler = (id: string) => {
      Swal.fire({
        title: "Apakah anda yakin ingin menghapus log crawler tersebut?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.value) {
          const res = await deleteCrawler(id);
          if (res.status == 200) {
            Swal.fire({
              position: "top-end",
              icon: "success",
              toast: true,
              title: "Berhasil! Data crawler berhasil dihapus",
              showConfirmButton: false,
              timer: 3000,
            });
            fetchDataCrawler();
          } else {
            Swal.fire({
              position: "top-end",
              icon: "error",
              toast: true,
              title: "Gagal! Terjadi kesalahan saat menghapus crawler",
              showConfirmButton: false,
              timer: 3000,
            });
          }
        }
      });
    };
    const filterProjectChange = async (value: any) => {
      modelSelect.value = value;
    };
    const filterTypeChange = async (value: any) => {
      if (value) {
        TypeValue.value = value;
      } else {
        TypeValue.value = "";
      }
    };
    const currentChange = (current: number) => {
      currentPage.value = current;
      fetchDataCrawler();
    };
    const itemsPerPageChange = (currentItemsPerPage: number) => {
      itemsPerPage.value = currentItemsPerPage;
      fetchDataCrawler();
    };
    const viewDetail = (data: any) => {
      let text_title = `Project : ${data.project_name} \n`;
      text_title += `Username : ${data.username} \n`;
      text_title += `Sumber : ${
        data.source == "media_cetak"
          ? "Media Cetak"
          : data.source == "media_tv"
          ? "Media TV"
          : data.source == "medsos"
          ? "Media Sosial"
          : "Media Online"
      } \n`;
      text_title += `Status : ${
        data.status == "Success"
          ? "Berhasil"
          : data.status == "Pending"
          ? "Dalama Antrian"
          : data.status == "Stopped"
          ? "Terhenti"
          : "Berjalan"
      } \n`;
      text_title += `Created At : ${moment(data.created_at).format(
        "YYYY-MM-DD HH:mm:ss"
      )} \n`;
      text_title += `Start Date : ${data.start_date} \n`;
      text_title += `End Date : ${data.end_date} \n`;
      if (data.executed_at) {
        text_title += `Excecuted At : ${moment(data.executed_at).format(
          "YYYY-MM-DD HH:mm:ss"
        )} \n`;
      }
      if (data.finished_at) {
        text_title += `Finished At : ${moment(data.finished_at).format(
          "YYYY-MM-DD HH:mm:ss"
        )} \n`;
      }
      if (data.stdout) {
        text_title += data.stdout;
      }
      Swal.fire({
        title: "Detail Crawler",
        html: `${writeContent(text_title)}`,
        icon: "info",
        confirmButtonText: "Ok",
        buttonsStyling: false,
        width: "600px",
        customClass: {
          confirmButton: "btn btn-light-primary",
        },
      });
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("Crawler", ["Log"]);
      fetchDataCrawler();
      getListProject();
      MenuComponent.reinitialization();
    });
    watch(selectedDataType, () => {
      fetchDataCrawler();
    });
    watch(selectedStatus, () => {
      fetchDataCrawler();
    });

    return {
      moment,
      submitCrawlerButton,
      FormValidator,
      tableHeaderCrawler,
      tableDataCrawler,
      tableKeyCrawler,
      FormDisplay,
      totalDataCrawler,
      is_loading,
      titleTabs,
      itemsPerPage,
      currentPage,
      TypeValue,
      modelSelect,
      dataProject,
      selectedStatus,
      selectedDataType,
      dataSource,
      statusOptions,
      dataTypeOptions,
      tanggalAwal,
      tanggalAkhir,
      writeContent,
      startCrawlback,
      getListProject,
      fetchDataCrawler,
      retrySelectedCrawler,
      forceStopSelectedCrawler,
      deleteSelectedCrawler,
      filterProjectChange,
      filterTypeChange,
      currentChange,
      itemsPerPageChange,
      viewDetail,
    };
  },
});
